import * as React from "react"
import { Helmet } from "react-helmet"
import _ from "lodash"
import { connectHits } from "react-instantsearch-dom";

function SeoStructuredData({ name, description, hits, type, publisher }) {

    const total = hits?.length
    const entity =
    {
        name: name,
        desc: description,
        list: _.map(hits, o => _.extend({
            name: `${o.bedroom} bed ${o.building ? o.building + ' ' : ''}${o.search_type === "sales" ? "for sale" : "to rent"} in ${o.display_address}`,
            url: (o.search_type === "sales") ?
                process.env.GATSBY_SITE_URL + '/property-for-sale/' + o.slug + '-' + o.objectID
                : process.env.GATSBY_SITE_URL + '/property-for-rent/' + o.slug + '-' + o.objectID

        }, o))

    }

    var ldJson = {
        "@context": "http://schema.org",
        "@type": type,
        "name": "Allegiance",
        "alternateName": "Allegiance Real Estate",
        "logo": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/brandlogo_1899d71195.png",
        // "rera": "23405",
        "numberOfEmployees": "180",
        "foundingDate": "2019-11-17",
        "awards":
          "1st place Damac Properties Awards 2022, 1st place Damac unity awards 2021, oxe the best partner award 2022, 1st place Damac Broker Awards 2023 ",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+971 5 641 44147",
          "areaServed": "Dubai",
          "email": "info@allegiance.ae",
          "availableLanguage": [
            "Arabic",
            "English",
            "Russian",
            "French",
            "German",
            "Spanish",
            "Portuguese",
            "Uzbek",
            "Ukrainian",
            "Ukrainian",
            "Urdu",
            "Italian",
            "Catalan",
            "Turkish",
            "Japanese",
            "Dutch",
            "Farsi",
            "Chinese",
          ]
        },
        "address": {
          "@type": "PostalAddress",
          "addressLocality": "Dubai, UAE",
          "postalCode": "00000",
          "streetAddress": "Suite 2804, CONTROL TOWER - Motor City",
          "telephone": "+971 5 641 44147",
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.9",
            "reviewCount": "5",
        },
        "sameAs": [
            "https://www.facebook.com/allegiance.ae",
            "https://www.linkedin.com/company/allegiance-real-estate/",
            "https://www.youtube.com/channel/UCluQT0ROUpAHg0IA0473kGw",
            "https://www.instagram.com/allegiance.ae/",
        ]
    };
    if (!_.isEmpty(publisher)) {
        ldJson['publisher'] = {
            "@type": "Corporation",
            "name": publisher.name,
            "logo": {
                "@type": "ImageObject",
                "url": publisher.logo,
                "width": 243,
                "height": 34
            }
        }
    }
    if (!_.isEmpty(entity)) {
        var itemListElement = [];
        if (!_.isEmpty(entity.list)) {
            _.forEach(entity.list, (val, key) => {
                itemListElement.push(
                    {
                        "@type": "ListItem",
                        "position": key + 1,
                        "url": val.url,
                        "name": val.name
                        // "item": {
                        //   "@type": "ListItem",
                        //   "@id": val.id,
                        //   "url": val.url,
                        //   "name": val.name
                        // }
                    }
                )
            })
            ldJson['mainEntity'] = {
                "@type": "ItemList",
                "numberOfItems": total,
                "name": entity.name,
                "description": entity.desc,
                "itemListElement": itemListElement
            }
        }
    }
    return (
        <Helmet>
            {total > 0 &&
            <script type="application/ld+json">
                {JSON.stringify(ldJson, null, 2)}
            </script>
            }
            
        </Helmet>
    )
}

const CustomSeoStructure = connectHits(SeoStructuredData)
export default CustomSeoStructure