import Loadable from "@loadable/component"
import { Link, navigate } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { Configure, InstantSearch } from "react-instantsearch-dom"
import FilterSearch from "../components/FilterSearch/FilterSearch"
import Layout from "../components/Layout/Layout"
import ConnectFields from "../components/SearchResults/ConnectFields"
import CustomInfiniteHits from "../components/SearchResults/CustomInfiniteHits"
import CustomNoHits from "../components/SearchResults/CustomNoHits"
import SearchPagination from "../components/SearchResults/SearchPagination"
import SearchResultsContainer from "../components/SearchResults/SearchResultsContainer"
import StatAndSort from "../components/SearchResults/StatAndSort"
import {
  INDEX_NAME,
  MAP_TOGGLE,
  SEARCH_CLIENT,
  createURL,
  getH1Text,
  getSeoDesc,
  getSeoTitle,
  searchStateToUrl,
  urlToSearchState,
} from "../components/SearchResults/utils"
import clsx from "clsx"
import SEO from "../components/Seo/seo"
import { contactURL } from "../site/urls"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import CustomSeoStructure from "../components/Seo/SeoStructuredData"


const SearchSideMap = Loadable(() =>
  import("../components/SearchResults/map/RenderMap")
)

const SearchResultsTemplate = props => {
  const { phone } = useCompanyInfo()
  const {isLargeScreen}=useDeviceMedia()
  const { location, pType, pStatus, pCategoryType, locationName, pSubPath } =
    props

  const [searchState, setSearchState] = useState(
    urlToSearchState(location, pSubPath)
  )

  const debouncedSetStateRef = useRef(null)
  const [showMap, setShowMap] = useState(false)
  const [showDesc, setShowDesc] = useState(false)

  useEffect(() => {
    setSearchState(urlToSearchState(location, pSubPath))
  }, [location])

  const onSearchStateChange = searchState => {
    clearTimeout(debouncedSetStateRef.current)
    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(props, searchState, locationName), searchState)
    }, 500)
    setSearchState(searchState)
  }

  const handleShowMapGrid = key => {
    setShowMap(key === MAP_TOGGLE.map)
  }

  const h1Text = getH1Text({ searchState, pType, capitalize: true })
  const infoText = getH1Text({ searchState, pType, capitalize: false })
  const seoDescription = getSeoDesc({ searchState, pType })

  const buyRent = pType === "sales" ? "for sale" : "to rent"

  let finalInfoText = (
    <>
      Explore our range of {infoText}. <Link to={contactURL}>Contact Allegiance Real Estate</Link> to find the right properties {buyRent} in Dubai.
    </>
  )

  let schemaDesc;
  if(location?.pathname === "/properties/for-sale/in-dubai/" || location?.pathname === "/properties/for-rent/in-dubai/") {
    schemaDesc = `Discover Allegiance: Your Premier Destination for Real Estate in Dubai. Our Expert Team is Ready to Assist You in Finding Your Dream Home, Selling Your Property, or Managing Your Investment Portfolio.`
  }
  

  return (
    <>
      <SEO title={h1Text} description={seoDescription} />
      <InstantSearch
        indexName={INDEX_NAME}
        searchClient={SEARCH_CLIENT}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}
        routing="true"
      >
        <Configure
          hitsPerPage={showMap ? 1000 : 8}
          page={0}
          filters={`publish:true AND search_type:${pType} AND department:${pCategoryType} AND status:"${pStatus}"`}
        />
        <CustomSeoStructure
          name={h1Text}
          description={schemaDesc || seoDescription}
          pType={pType}
          type="SearchResultsPage"
          publisher={{ name: "Allegiance Real Estate Dubai", logo: "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/brandlogo_1899d71195.png" }}
        />
        <Layout
          pageLayout="search-results-page"
          className="search-results-page"
          popularSearch="category1"
        >
          {!isLargeScreen&&
       <div className="social-section">
          <a href={getWhatsAppURL(phone)} target="_blank">
            <img
              src={whatsappImg}
              alt="social-whatsapp"
              className="social-icon social-whatsapp"
            />
          </a>
          <a href={"tel:" + phone}>
            <img
              src={balckPhoneImg}
              alt="social-phone"
              className="social-icon social-phone"
            />
          </a>
        </div>
        }

          <FilterSearch searchState={searchState} pType={pType} />
          <SearchResultsContainer className="search-results-wrapper search-results-wrapper-padding">
            <div
              className={clsx(
                "search-results-section",
                showMap && "map-section-view"
              )}
            >
              <h1 className="search-title">{h1Text}<i className="icon-info" onClick={() => setShowDesc(!showDesc)}></i></h1>
              {showDesc && <p className="search-text">{finalInfoText}</p>}

              <StatAndSort
                showMap={showMap}
                handleShowMapGrid={handleShowMapGrid}
              />
              {!showMap && (
                <>
                  <CustomInfiniteHits showMap={showMap} />
                  <CustomNoHits
                    status={pStatus}
                    department={pCategoryType}
                    search_type={pType}
                  />
                  <SearchPagination />
                </>
              )}
            </div>
            <SearchSideMap showMap={showMap} />
          </SearchResultsContainer>
          <SearchSideMap showMap={showMap} mapView />
        </Layout>
        <ConnectFields />
      </InstantSearch>
    </>
  )
}

export default SearchResultsTemplate
