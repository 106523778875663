import React from "react"
import PropertyCard from "../PropertyCard/PropertyCard"
import gql from "graphql-tag"
import {  connectStats } from "react-instantsearch-dom"
import { useQuery } from "@apollo/client"
import _ from "lodash"

const NoHits = ({ status, department, search_type,nbHits }) => {
  const noResultPropertiesQuery = gql`
    query NoResultProperties(
      $status: String!
      $department: String!
      $search_type: String!
    ) {
      properties(
        limit: 6
        where: {
          status: $status
          department: $department
          search_type: $search_type
          publish: true
        }
      ) {
        id
        search_type
        status
        department
        description
        price
        price_qualifier
        currency
        display_address
        area
        bedroom
        bathroom
        reception
        imagetransforms
        building
        slug
        title
        images
        floorarea_max
        floorarea_type
        crm_negotiator_id
      }
    }
  `

  const { loading, error, data } = useQuery(noResultPropertiesQuery, {
    variables: {
      status: status,
      department: department,
      search_type: search_type,
    },
  })

  const properties = data?.properties

  if (nbHits!==0) return null

  return (
    <div className="no-hits-section">
      <h3 className="no-hit-title">
        Unfortunately, we do not currently have any properties that match your
        search criteria.
      </h3>
      <p className="description">
        We have selected some of our showcase properties for you to browse
        below. Alternatively, you can search again in the bar above.
      </p>
      <div className="search-results-list-wrapper">
        {properties &&
          properties.map(property => (
            <PropertyCard
              key={property.id}
              price={property.price}
              title={property.description}
              address={property.display_address}
              bedroom={property.bedroom}
              bathroom={property.bathroom}
              floorarea={property.floorarea_max}
              //   floorarea_type={property.floorarea_type}
              building={property.building[0]}
              slug={property.slug}
              image={property.images[0]}
              id={property.id}
              search_type={property.search_type}
              imagetransforms={property.imagetransforms}
              propertyData={{display_address: property.display_address, crm_negotiator_id: property.crm_negotiator_id}}
            />
          ))}
      </div>
    </div>
  )
}

const CustomNoHits = connectStats(NoHits)

export default CustomNoHits
