import React from "react"
import SearchResultsTemplate from "../../templates/search-results-template"
import { propertyForSaleURL } from "../../site/urls"

const PropertySale = props => {
  const location = props.location

  return (
    <SearchResultsTemplate
      locationName={propertyForSaleURL}
      location={location}
      pCategoryType="residential"
      pType="sales"
      pSubPath="for-sale"
      pStatus="For Sale"
    />
  )
}

export default PropertySale
