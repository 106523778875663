import React from "react"
import { connectHits } from "react-instantsearch-dom"
import PropertyCard from "../PropertyCard/PropertyCard"
import _ from "lodash"
// import { useAutoAnimate } from "@formkit/auto-animate/react"
import {
  inViewOptions2,
  contentItemStagger
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const InfiniteHits = ({ hits, showMap }) => {
  // const [parent] = useAutoAnimate({ duration: 500 })

  if (showMap || _.isEmpty(hits)) return null

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        
    <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger} className="search-results-list-wrapper">
      {hits.map(hit => (
        <PropertyCard
          key={hit.objectID}
          price={hit.price}
          title={hit.description}
          address={hit.display_address}
          bedroom={hit.bedroom}
          bathroom={hit.bathroom}
          floorarea={hit.floorarea_max}
          floorarea_type={hit.floorarea_type}
          building={hit.building[0]}
          slug={hit.slug}
          image={hit.images? hit.images[0] : null}
          id={hit.objectID}
          search_type={hit.search_type}
          propertyData={{display_address: hit.display_address, negotiator_details: hit.negotiator_details}}
        />
      ))}
    </motion.div>
      )}
    </InView>
  )
}

const CustomInfiniteHits = connectHits(InfiniteHits)

export default CustomInfiniteHits
